import request from '@/common/utils/request'
import Vue from "vue";

export function getArticleList(data) {
  return request({
    url: Vue.prototype.$sadsdasBaseUrl + '/adminapi/content/get-article-list',
    method: 'post',
    data
  })
}

export function addArticle(data) {
  return request({
    url: Vue.prototype.$sadsdasBaseUrl + '/adminapi/content/add-article',
    method: 'post',
    data
  })
}

export function updateArticle(data) {
  return request({
    url: Vue.prototype.$sadsdasBaseUrl + '/adminapi/content/update-article',
    method: 'post',
    data
  })
}

export function deleteArticle(data) {
  return request({
    url: Vue.prototype.$sadsdasBaseUrl + '/adminapi/content/delete-article',
    method: 'post',
    data
  })
}

export function getAllSubjectList() {
  return request({
    url: Vue.prototype.$sadsdasBaseUrl + '/adminapi/content/get-all-subject-list',
    method: 'post'
  })
}
