<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <el-button type="primary" size="small" @click="showAddDialogForm"
                >新增文章</el-button
              >
            </el-form-item>
            <!--            <el-form-item>-->
            <!--              <el-button type="primary" size="small"   @click="search">刷新</el-button>-->
            <!--            </el-form-item>-->
          </el-form>

          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="">
              <el-input
                v-model="searchForm.title"
                size="small"
                placeholder="请输入文章标题"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.subjectId"
                filterable
                clearable
                placeholder="请选择关联分类"
              >
                <el-option
                  v-for="item in subjectList"
                  :key="item.id"
                  :label="
                    (item.type == 1 ? '小程序 - ' : '官网 - ') + item.title
                  "
                  :value="item.id"
                  v-if="(item.type == 2 && item.parentId > 0) || item.type == 1"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="search"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      v-fit-columns
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      :height="tableHeight"
      border
    >
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>
      <el-table-column prop="id" label="id" min-width="80"> </el-table-column>
      <el-table-column prop="sort" label="排序" min-width="80">
      </el-table-column>
      <el-table-column prop="title" label="文章标题" min-width="150">
      </el-table-column>
      <el-table-column prop="title2" label="文章副标题" min-width="120">
        <template slot-scope="scope">
          <div v-html="scope.row.title2" class="line2"></div>
        </template>
      </el-table-column>
      <el-table-column prop="subjectTitles" label="关联分类" min-width="200">
      </el-table-column>
      <el-table-column prop="coverImg" label="顶部图片" min-width="110">
        <!-- <template slot-scope="scope">
          <template>
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.timg"
              :preview-src-list="[scope.row.timg]"
            >
            </el-image>
          </template>
        </template> -->
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.timg"
              :preview-src-list="[scope.row.timg]"
              @click.stop="handleClickItem"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="coverImg"
        fixed="right"
        label="封面"
        min-width="110"
      >
        <!-- <template slot-scope="scope">
          <template>
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.coverImg"
              :preview-src-list="[scope.row.coverImg]"
            >
            </el-image>
          </template>
        </template> -->
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.coverImg"
              :preview-src-list="[scope.row.coverImg]"
              @click.stop="handleClickItem"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        fixed="right"
        label="文章内容"
        min-width="200"
      >
        <template slot-scope="scope">
          <div class="line5" v-html="deleteHtml(scope.row.content)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="address" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :total="total"
          :page-sizes="[20, 50, 100]"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog
      :title="editForm.id == 0 ? '新增文章' : '修改文章'"
      :visible.sync="editFormVisible"
      width="1000px"
      top="1vh"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <el-form-item label="文章标题" label-width="100px" prop="title">
          <el-input
            v-model="editForm.title"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章封面图" label-width="100px" prop="coverImg ">
          <el-input v-model="editForm.coverImg" style="display: none" />

          <el-upload
            v-model="editForm.coverImg"
            action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
            :before-upload="beforeAvatarUpload"
            class="avatar-uploader"
            :headers="{
              'X-Token': token,
            }"
            :on-success="handleAvatarSuccess"
            :show-file-list="false"
          >
            <img v-if="imageUrl" class="avatar" :src="imageUrl" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="发布时间" label-width="100px" prop="date1">
          <el-date-picker
            v-model="editForm.date1"
            size="mini"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            type="datetime"
            placeholder="请选择日期和时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="排序" label-width="100px" prop="sort">
          <el-input
            v-model="editForm.sort"
            type="number"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="官网分类" label-width="100px" prop="subject1">
          <el-checkbox-group v-model="subject1">
            <el-checkbox
              v-for="(item, index) in subjectList"
              :label="item.id + ''"
              v-if="item.type == 2 && item.parentId > 0"
              >{{ item.title }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="小程序分类" label-width="100px" prop="subject2">
          <el-checkbox-group v-model="subject2">
            <el-checkbox
              v-for="(item, index) in subjectList"
              :label="item.id + ''"
              v-if="item.type == 1"
              >{{ item.title }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="文章副标题" label-width="100px" prop="title2">
          <el-input
            v-model="editForm.title2"
            size="small"
            autocomplete="off"
            type="textarea"
            :autosize="true"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="封面" label-width="100px" prop="coverImg">
          <el-input
            v-model="editForm.coverImg"
            size="small"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="顶部图片" label-width="100px" prop="timg">
          <!-- <el-input
            v-model="editForm.timg"
            size="small"
            autocomplete="off"
          ></el-input> -->
          <el-upload
            v-model="editForm.timg"
            action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
            :before-upload="beforeAvatarUploadthree"
            class="avatar-uploader"
            :headers="{
              'X-Token': token,
            }"
            :on-success="handleAvatarSuccessthree"
            :show-file-list="false"
          >
            <img v-if="imageUrlthree" class="avatar" :src="imageUrlthree" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="微信顶部图片" label-width="100px" prop="wxTopImg">
          <el-upload
            v-model="editForm.wxTopImg"
            action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
            :before-upload="beforeAvatarUploadtwo"
            class="avatar-uploader"
            :headers="{
              'X-Token': token,
            }"
            :on-success="handleAvatarSuccesstwo"
            :show-file-list="false"
          >
            <img v-if="imageUrltwo" class="avatar" :src="imageUrltwo" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <!-- <el-input
            v-model="editForm.wxTopImg"
            size="small"
            autocomplete="off"
          ></el-input> -->
        </el-form-item>
        <el-form-item label="微信缩略图" label-width="100px" prop="timg">
          <!-- <el-input
            v-model="editForm.wxImg"
            size="small"
            autocomplete="off"
          ></el-input> -->
          <el-upload
            v-model="editForm.wxImg"
            action="https://ybh2022.gengduoke.com/adminapi/uploadToQiNiu"
            :before-upload="beforeAvatarUploadfour"
            class="avatar-uploader"
            :headers="{
              'X-Token': token,
            }"
            :on-success="handleAvatarSuccessfour"
            :show-file-list="false"
          >
            <img v-if="imageUrlfour" class="avatar" :src="imageUrlfour" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <!--        <el-form-item label="内容" :label-width="labelWidth" prop="content">-->
        <!--          <ckeditor :editor="editor" v-model="editForm.content" :config="editorConfig"></ckeditor>-->
        <!--        </el-form-item>-->
        <el-form-item>
          <VueUeditorWrap v-model="editForm.content" :config="editorConfig" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >{{ editForm.id == 0 ? "添 加" : "修 改" }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "../../../common/utils/auth";

import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import { getPlateList2, addPlate, updatePlate } from "../../../api/plate";
import { getSystemSet } from "../../../api/system_setting";
// import ClassicEditor from '@/components/ckeditor/ckeditor'
// import CKEditor from '@ckeditor/ckeditor5-vue2'
import {
  getArticleList,
  addArticle,
  updateArticle,
  getAllSubjectList,
  deleteArticle,
} from "../../../api/cms";
import VueUeditorWrap from "vue-ueditor-wrap";

export default {
  name: "articleManage",
  components: {
    // ckeditor: CKEditor.component,
    VueUeditorWrap,
  },
  data() {
    return {
      imageUrltwo: "",
      imageUrlthree: "",
      imageUrlfour: "",

      message: "",
      imageUrl: "",
      // 简单配置
      editorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        zIndex: 5000,
        // 上传文件接口, 报错属于正常，若需要验证可使用(也是盗大神的)http://35.201.165.105:8000/controller.php
        // 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
        // serverUrl: '/static/UEditor/php/controller.php',
        UEDITOR_HOME_URL: "/UEditor/",
        maximumWords: 999999,
      },
      token: "",
      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {
        id: 0,
        title: "",
        title2: "",
        content: "",
        coverImg: "",
        timg: "",
        subjectIds: "",
        wxImg: "",
        wxTopImg: "",
        createTime: "",
        sort: "",
        picture: "",
        date1: "",
      },
      date: {
        date1: "",
        date2: "",
        date3: "",
      },
      editRules: {
        title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        date1: [{ required: true, message: "请选择发布时间", trigger: "blur" }],
        sort: [{ required: true, message: "请填写排序", trigger: "blur" }],
        // coverImg: [
        //   { required: true, message: "请上传封面图", trigger: "change" },
        // ],
        coverImg: [
          { required: true, trigger: "change", message: "请上传封面图" },
        ],
      },
      loadingDiv: null,
      searchForm: {
        title: "",
        subjectId: "",
      },
      subjectList: [],
      subject1: [],
      subject2: [],

      labelWidth: "100px",
      // actionUrl:"",
      // editor: ClassicEditor,
      // editorConfig: {
      //   toolbar: ['FontSize','FontColor','FontBackgroundColor','Alignment', 'Bold', 'Italic', 'ImageInsert','sourceEditing'],
      //   ckfinder: {
      //     uploadUrl: '',
      //     options: {
      //       resourceType: 'Images'
      //     }
      //   }
      // }
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    this.getDataList(this.currentPage);

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 198;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 198;
      });
    };

    this.init();
  },
  methods: {
    handleClickItem() {
      this.$nextTick(() => {
        let domImageMask = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      });
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return true;
    },
    beforeAvatarUploadtwo(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return true;
    },
    beforeAvatarUploadthree(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return true;
    },
    beforeAvatarUploadfour(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return true;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
      this.editForm.coverImg = res.data;
    },
    handleAvatarSuccesstwo(res, file, fileList) {
      this.imageUrltwo = URL.createObjectURL(file.raw);
      console.log(res);
      this.editForm.wxTopImg = res.data;
    },
    handleAvatarSuccessthree(res, file, fileList) {
      this.imageUrlthree = URL.createObjectURL(file.raw);
      console.log(res);
      this.editForm.timg = res.data;
    },
    handleAvatarSuccessfour(res, file, fileList) {
      this.imageUrlfour = URL.createObjectURL(file.raw);
      console.log(res);
      this.editForm.wxImg = res.data;
    },
    inSubject(subjectIds, subjectId) {
      if (util.isEmpty(subjectIds)) {
        return false;
      }

      let subs = "," + subjectIds + ",";
      if (subjectIds.indexOf(subjectId + "") != -1) {
        return true;
      }
      return false;
    },
    deleteHtml(val) {
      return util.deleteHtml(val);
    },
    isEmpty(val) {
      return util.isEmpty(val);
      console.log(util.isEmpty());
    },
    fmtDate(val) {
      return util.fmtDate(new Date(val * 1000));
    },
    init() {
      // getAllSubjectList().then(res=>{
      //   if (res.code == 1){
      //     this.subjectList = res.data;
      //   }
      // });
    },

    showAddDialogForm() {
      let t = getToken();
      this.token = t;
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      this.editForm.id = 0;
      this.editForm.title = "";
      this.editForm.title2 = "";
      this.editForm.content = "";
      this.editForm.coverImg = "";
      this.editForm.timg = "";
      this.editForm.createTime = "";
      this.editForm.date1 = "";
      this.editForm.sort = 999;

      this.editFormVisible = true;
    },
    handleEdit(index, row) {
      this.editForm = Object.assign({}, row);

      let t = getToken();
      this.token = t;
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }
      // this.editForm.id = row.id;
      // this.editForm.title = row.title;
      // this.editForm.title2 = row.title2;
      // this.editForm.content = row.content;
      // this.editForm.coverImg = row.coverImg;
      this.imageUrl = row.coverImg;
      this.imageUrlthree = row.timg;
      this.imageUrltwo = row.wxTopImg;
      this.imageUrlfour = row.wxImg;
      console.log(row.coverImg == row.wxImg);
      // this.editForm.timg = row.timg;
      // this.editForm.subjectIds = row.subjectIds;
      // this.editForm.wxImg = row.wxImg;
      // this.editForm.wxTopImg = row.wxTopImg;
      // this.editForm.createTime = row.createTime;
      this.editForm.date1 = row.createTime * 1000;
      // this.editForm.sort = row.sort;
      // if (util.isNotEmpty(row.subjectIds)){
      //   this.subject1 = row.subjectIds.split(",");
      //
      // }
      // console.log(row.subconsole.log(row.subjectIds);

      this.subject1 = [];
      this.subject2 = [];

      if (util.isNotEmpty(row.subjectIds)) {
        let subjectArr = row.subjectIds.split(",");
        for (let i in subjectArr) {
          let id = parseInt(subjectArr[i]);
          for (let j in this.subjectList) {
            if (id == this.subjectList[j].id) {
              if (this.subjectList[j].type == 1) {
                this.subject2.push(id + "");
                continue;
              } else if (this.subjectList[j].type == 2) {
                this.subject1.push(id + "");
                continue;
              }
            }
          }
        }
      }

      this.editFormVisible = true;
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;

          if (this.editForm.date1) {
            this.editForm.createTime = this.editForm.date1 / 1000;
          }

          let subjectArr = this.subject1.concat(this.subject2);
          this.editForm.subjectIds = subjectArr;

          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }
          const loadingDiv = util.showLoading(loadingText);
          let func = updateArticle;
          if (this.editForm.id == 0) {
            func = addArticle;
          }
          func(this.editForm)
            .then((res) => {
              let title = "修改";
              if (this.editForm.id == 0) {
                title = "添加";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.getDataList(1);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm("是否确认删除文章: " + row.title, "删除确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return deleteArticle({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList(this.currentPage);
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },

    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      getArticleList({
        pageSize: this.pageSize,
        pageNum: pageNum,
        title: this.searchForm.title,
        subjectId: this.searchForm.subjectId,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.currentPage = pageNum;
            this.total = data.data.total;
            this.tableData = data.data.dataList;
            this.subjectList = data.subjectList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDataList(1);
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.avatar-uploader .el-upload {
  border: 1px dashed yellowgreen;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.fengmiantu {
  width: 50px;
  height: 50px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.line5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}

.el-form-item__content {
  line-height: 0px;
}

.el-loading-custom-class {
  z-index: 99999 !important;
}
</style>
